import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`aslade`]}
        title="Home"
      />

      <section class="px-4 md:pt-16">
        <img src="https://looperinsights.com/wp-content/uploads/2019/02/alex.jpg" class="hidden sm:block w-4/12 mt-10 ml-16 mb-16 rounded float-right" alt="" />

        <div class="-mx-2">
          <div class=" px-2 sm:pr-10 mt-10">
            <h1 class="text-3xl sm:text-4xl md:text-5xl mb-6 font-light leading-tight font-heading max-w-sm md:max-w-lg">
              <span class="text-orange-600 leading-relaxed"> 👋 Hi, I'm Alex</span>
              <br />
              I help people start software businesses.
            </h1>
          </div>
        </div>
        <div>


        <p class="mt-8 md:mt-16 text-lg md:text-xl mb-8 text-gray-800 leading-relaxed max-w-md md:max-w-lg">
          I'm a software developer with a varied history: I've been an indy games producer, a lighting &amp; sound technician, a UX researcher, and startup advisor.
        </p>
        <p class="text-lg md:text-xl mb-8 text-gray-800 leading-relaxed max-w-md md:max-w-xl">
          More recently I've been helping startups and scaleups run effective software and product teams. I was Founder / CTO of <a href="#" class="text-blue-800 underline">Shaken.com</a>, and Head of Software at <a href="#" class="text-blue-800 underline">Beano.com</a>.
        </p>

        <p class="text-lg md:text-xl mb-8 text-gray-800 leading-relaxed max-w-md md:max-w-xl">
              I'm a software developer with a varied history: I've been an indy games producer, a lighting &amp; sound technician, a UX researcher, and startup advisor.
            </p>
            <p class="text-lg md:text-xl mb-8 text-gray-800 leading-relaxed max-w-md md:max-w-xl">
              More recently I've been helping startups and scaleups run effective software and product teams. I was Founder / CTO of <a href="#" class="text-blue-800 underline">Shaken.com</a>, and Head of Software at <a href="#" class="text-blue-800 underline">Beano.com</a>.
            </p>




        </div>
      </section>

    </Layout>
  );
}

export default IndexPage;
